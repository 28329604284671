import { defineStore } from 'pinia';

const caractristiques = [
  {
    clef: 'style',
    type: 'select',
    valeurs: ['Traditionnel', 'Contemporain', 'Landaise', 'Arcachonnaise'],
    libelle: 'Style',
    libelleCourt: 'Style',
  },
  {
    clef: 'type_construction',
    type: 'select',
    valeurs: ['Étage', 'Plain pied'],
    libelle: 'Type de construction',
    libelleCourt: 'Impl.',
  },
  {
    clef: 'type_toiture',
    type: 'select',
    valeurs: ['Charpente', ' Toit Terrasse', 'Charpente + Toit Terrasse'],
    libelle: 'Type de toiture',
    libelleCourt: 'Toiture',
  },
  {
    clef: 'surface_habitable',
    unite: 'm2',
    type: 'number',
    libelle: 'Surface habitable',
    libelleCourt: 'S. hab.',
  },
  {
    clef: 'surface_sejour',
    unite: 'm2',
    type: 'number',
    libelle: 'Surface séjour',
    libelleCourt: 'S. séj.',
  },
  {
    clef: 'surface_plancher',
    unite: 'm2',
    type: 'number',
    libelle: 'Surface plancher',
    libelleCourt: 'S. plancher',
  },
  {
    clef: 'longueur_facades',
    unite: 'm',
    type: 'number',
    libelle: 'Longueur façades',
    libelleCourt: 'L. façades',
  },
  {
    clef: 'nb_pieces',
    type: 'number',
    libelle: 'Nombre de pièces',
    libelleCourt: 'Pièces',
  },
  {
    clef: 'nb_chambres',
    type: 'number',
    libelle: 'Nombre de chambres',
    libelleCourt: 'Chambres',
  },
  {
    clef: 'nb_salles_de_bain',
    type: 'number',
    libelle: 'Nombre de salles de bain',
    libelleCourt: 'Salles de bain',
  },
  {
    clef: 'nb_garages',
    type: 'number',
    libelle: 'Nombre de garages',
    libelleCourt: 'Garages',
  },
  {
    clef: 'carport',
    type: 'boolean',
    libelle: 'Carport ?',
    libelleCourt: 'Carport',
  },
  {
    clef: 'terrasse',
    type: 'boolean',
    libelle: 'Terrasse ?',
    libelleCourt: 'Terrasse',
  },
  {
    clef: 'terrasse_couverte',
    type: 'boolean',
    libelle: 'Terrasse couverte ?',
    libelleCourt: 'T. couverte',
  },
];

const emptyVersion = () => ({
  id: -1,
  libelle: '',
  description: '',
  tarif: 0,
  medias: [],
  documents: [],
  modele: null,
  caracteristiques: [],
});

export const useConstructionVersionsStore = defineStore({
  id: 'construction-versions-store',
  state: () => {
    return {
      currentVersion: emptyVersion(),
      caractristiquesDefinitions: caractristiques,
      api: useApi.constructionVersions,
    };
  },
  actions: {
    async fetch(id) {
      const response = await this.api.findOne(id);
      this.currentVersion = response.data;
      return response;
    },

    async create(item) {
      return await this.api.create(item);
    },

    async update(item) {
      await this.api.update(item);
    },

    async delete(item) {
      return await this.api.delete(item);
    },

    async refresh() {
      return await this.fetch(this.currentVersion.id);
    },

    async updateCaracteristique(clef, valeur) {
      const carac = this.currentVersion.caracteristiques.find((c) => c.clef === clef);
      if (carac) {
        carac.valeur = valeur;
      } else {
        this.currentVersion.caracteristiques.push({ clef, valeur });
      }
      this.currentVersion.searchString = this.searchString(this.currentVersion);
      await this.update(this.currentVersion);
    },

    generateReference(gamme, modele, libelleVersion) {
      return `${gamme ? gamme.slug + '-' : ''}${modele.slug}-${useSlug(libelleVersion)}`;
    },

    reset() {
      this.currentVersion = null;
    },
  },
  getters: {
    reference: (state) => (version) => {
      return state.generateReference(version.gamme, version.modele, version.libelle);
    },

    searchString: (state) => (version) => {
      const carac = version.caracteristiques
        .map((c) => {
          const caracDef = state.caractristiquesDefinitions.find((def) => def.clef === c.clef);
          if (caracDef.type === 'boolean') {
            return c.valeur ? caracDef.libelleCourt : '';
          }
          return c.valeur
            ? `| ${caracDef.libelleCourt} : ${c.valeur} ${caracDef.unite || ''}`
            : '';
        })
        .join(' ');
      return `${version.libelle} ${carac}`;
    },
  },
});
